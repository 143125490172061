<template>
  <div class="homePage">
    <HomeHeader :companyOption="companyOption" @changeCompany="changeCompany"></HomeHeader>
    <div class="contentArea">
      <!-- <div class="leftLayout">
        <div class="noticeList">
          <p class="title">待办通知</p>
          <div class="cells" :style="'height:'+noticeHeight+'px;'">

            <div class="cell" v-for="(item,index) in messageList" :key="index" @click="messageJump(item)">
              <div class="left">
                <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
                <span>{{item.serviceName}}</span>
              </div>
              <span class="notice" v-if="item.msgValue">{{item.msgValue}}</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="leftLayout">
        <div class="topApp" ref="topApp">
          <p class="title">应用中心</p>
          <div class="appBox">
            <div class="oneApp" v-for="(item, index) in productsMenu" :key="index" @click="platformJump(item)">
              <img :src="item.iconUrl ? item.iconUrl : defaultPng" alt="" />
              <div>
                <p class="p1">{{ item.productName }}</p>
                <p class="p2">{{ item.productDesc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightLayout">
        <div class="common-tab-box">
          <div class="common-tab-left">
            <div
              :class="tabIndex == index ? 'common-tab-list common-tab-active' : 'common-tab-list'"
              v-for="(item, index) in tabList"
              :key="index"
              @click="tabClick(item, index)"
            >
              {{ item.tabName }}({{index == 0 ? 5 : 3}})
            </div>
          </div>
          <!-- <div class="common-tab-right" @click="msgMore()">更多</div> -->
        </div>
        <div class="notice-list" v-show="tabIndex == 0" v-for="(item, index) in dealList" :key="index">
          <div class="notice-list-left"><span ></span>{{ item.content || '-' }}</div>
          <div class="notice-list-right">{{ item.createTime | getTime }}</div>
        </div>
        <p class="no-data" v-show="tabIndex == 0 && dealList.length == 0">暂无数据</p>
        <div class="notice-list" v-show="tabIndex == 1" v-for="(item, index) in messageList" :key="index">
          <div class="notice-list-left"><span ></span>{{ item.content || '-' }}</div>
          <div class="notice-list-right">{{ item.createTime | getTime }}</div>
        </div>
        <p class="no-data" v-show="tabIndex == 1 && messageList.length == 0">暂无数据</p>
      </div>
    </div>
    <div class="reportLayout">
      <div class="common-tab-box " style="margin:4px 0 0 0" v-if="reportList && reportList.length > 1">
        <div class="common-tab-left">
          <div
            :class="reportTabIndex == index ? 'common-tab-list common-tab-active' : 'common-tab-list'"
            v-for="(item, index) in reportList"
            :key="index"
            @click="reportTabClick(item, index)"
          >
            {{ item.menuName }}
          </div>
        </div>
      </div>
      <div class="report-content" v-if="reportPath">
        <iframe :src="reportPath" class="iframe-sty"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import newEcharts from 'echarts';
import HomeHeader from '@/components/homeHeader.vue';
import toOther from './mixins/toOther';
export default {
  name: 'marketingDigitalPlatform',
  components: {
    HomeHeader,
  },
  data() {
    return {
      defaultPng: require('../../assets/personalPage/icon3.png'),
      currentTenantGuid: null,
      currentStaffGuid: null,
      companyOption: [],
      organisationName: '--',
      positionName: '--',
      dealList: [
        {
          content: '您的任务包配置申请被驳回，请确认',
          createTime: '2023-03-24 11:23',
        },
        {
          content: '您有市场推广计划申请，请审批',
          createTime: '2023-03-23 15:40',
        },
        {
          content: '您有立项审批申请，请审批',
          createTime: '2023-03-21 17:15',
        },
        {
          content: '您有运营报告申请，请审批',
          createTime: '2023-03-17 10:37',
        },
        {
          content: '您有2月任务包待确认',
          createTime: '2023-02-10 10:13',
        },
      ],
      messageList: [
        {
          content: '任务包配置申请已通过，请知晓',
          createTime: '2023-03-27 09:10',
        },
        {
          content: '您有新的任务包待发布，请处理',
          createTime: '2023-03-24 16:10',
        },
        {
          content: '您的立项申请已通过，请知晓',
          createTime: '2023-03-22 14:49',
        },
      ],
      productsMenu: [],
      dimen: 'order', //order=按报货 flow=按流向
      isSaleMan: 1, //业务员和其他为1，省总为0
      echartHeight: 0,
      noticeHeight: 0,
      tabIndex: 0,
      tabList: [{ tabName: '待办' }, { tabName: '消息' }],
      messageType: '1', //消息类型 1待办 2消息
      dealTotalRows: 0,
      messageTotalRows: 0,
      reportTabIndex:0,
      reportList: [],
      reportPath: null,
    };
  },
  mixins: [toOther],
  created() {
    document.title = '个人中心';
    localStorage.removeItem('loginType');
    if(localStorage.menuList){
      let menuList = JSON.parse(localStorage.menuList);
      if(menuList && menuList.length > 0){
        menuList.forEach(i=>{
          if(i.menuName == '主页' ){
            this.reportList = i.children || [];
          }
        })
      }
    }
    // https://report.csbr.cn/Look?pageGuid=27195e26e0fe4aaeb152b5f3421f8fd2
    this.getTenenants();
  },
  mounted() {
    this.noticeHeight = window.innerHeight - 170;
  },
  methods: {
    tabClick(item, index) {
      this.tabIndex = index;
      this.messageType = index == 0 ? '1' : '2';
    },
    reportTabClick(item,index){
      this.reportTabIndex = index;
      this.reportPath = `${item.path}&token=${localStorage.token}&tenantGuid=${this.currentTenantGuid}`;
    },
    //获取消息列表
    getMessageList() {
      let query = {
        messageType: 2,
        pageIndex: 1,
        pageSize: 10,
        tenantGuid: this.currentTenantGuid,
        staffGuid: this.currentStaffGuid,
      };
      this.api.postJson(`/${serverConfig.msConfigureService}/message/data/get-message-list`, query).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          this.messageList = res.data.data.records || [];
          this.messageTotalRows = res.data.data.totalRows;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取待办列表
    getDealtList() {
      let query = {
        messageType: 1,
        pageIndex: 1,
        pageSize: 10,
        tenantGuid: this.currentTenantGuid,
        staffGuid: this.currentStaffGuid,
      };
      this.api.postCan(`/${serverConfig.msConfigureService}/message/data/get-message-list`, query).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          this.dealList = res.data.data.records || [];
          this.dealTotalRows = res.data.data.totalRows;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    messageRemark(item) {
      this.api.postJson(`/${serverConfig.msConfigureService}/message/data/update-state`, [item.guid]).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          this.$message.success('标记成功！');
          this.getMessageList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取消息列表
    // getMessageList() {
    //   var menuList = JSON.parse(localStorage.menuList);
    //   var tenantGuid = this.currentTenantGuid;
    //   var currentStaffGuid = this.currentStaffGuid;
    //   this.api.get('/ms-sales-perform-service/msg-group/get?menuGuid=' + menuList[0].guid + '&tenantGuid=' +
    //     tenantGuid + '&staffGuid=' + currentStaffGuid).then((res) => {
    //       if (res.data.code == '00000') {
    //         this.messageList = res.data.data;
    //       } else {
    //         this.$message.error(res.data.msg);
    //       }
    //     });
    // },
    //切换公司
    changeCompany(guid) {
      this.getMenuList(guid);
    },
    getMenuList(tenantGuid) {
      let self = this;
      self.api
        .get(
          '/ms-common-admin-platform-auth-service/menu/data/get-menu-by-currentuser?' +
            `appType=01&tenantGuid=` +
            tenantGuid +
            `&appSideIdentifier=QYD`,
        )
        .then(res => {
          if (res.data.code == '00000') {
            self.menuList = res.data.data;
            if (res.data.data[0].menuName == '主页') {
              this.getCurrentstaff(tenantGuid, res.data.data[0].path);
              // window.location = location.origin + res.data.data[0].path + "?username=" + self.$route.query
              //   .username +
              //   '&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid;
            }
          } else {
            self.$message.error(res.data.msg);
            self.loadingInstance.close();
          }
        });
    },
    getCurrentstaff(tenantGuid, path) {
      let self = this;
      self.api.get('/ms-personnel-service/staff/data/get-currentstaff?' + `tenantGuid=` + tenantGuid).then(res => {
        if (res.data.code == '00000') {
          self.$store.commit('$_setUserId', res.data.data.userGuid);
          localStorage.setItem('userInfo', JSON.stringify(res.data.data));
          window.location.replace(
            location.origin +
              path +
              '?username=' +
              self.$route.query.username +
              '&password=' +
              self.$route.query.password +
              '&currentTenantGuid=' +
              tenantGuid,
          );
        } else {
          self.$message.error(res.data.msg);
          self.loadingInstance.close();
        }
      });
    },
    //获取当前用户公司信息
    getTenenants() {
      var userGuid = localStorage.userId;
      this.api.get('/ms-personnel-service/staff-home/tenants?userGuid=' + userGuid).then(res => {
        if (res.data.code == '00000') {
          this.companyOption = res.data.data.records;
          this.currentTenantGuid = this.$route.query.currentTenantGuid;
          this.companyOption.forEach(item => {
            if (item.tenantGuid == this.currentTenantGuid) {
              this.currentStaffGuid = item.staffGuid;
            }
          });
          if(this.reportList && this.reportList.length >0){
            this.reportPath = `${this.reportList[0].path}&token=${localStorage.token}&tenantGuid=${this.currentTenantGuid}`;
          }
          console.log(this.reportPath)
          // this.getDealtList();
          // this.getMessageList();
          this.getOrgAndProduct();
          // this.reportPath = `https://report.csbr.cn/Look?pageGuid=27195e26e0fe4aaeb152b5f3421f8fd2&token=${localStorage.token}&tenantGuid=${this.currentTenantGuid}`;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取当前公司下的菜单
    getOrgAndProduct() {
      this.api
        .get(
          '/ms-personnel-service/staff-home/org-and-product?staffGuid=' +
            this.currentStaffGuid +
            '&tenantGuid=' +
            this.currentTenantGuid,
        )
        .then(res => {
          if (res.data.code == '00000') {
            this.organisationName = res.data.data.organisationName;
            this.positionName = res.data.data.positionName;
            this.productsMenu = res.data.data.products;
            this.productsMenu.forEach(item => {
              if (item.icon) {
                let obj = JSON.parse(item.icon);
                this.$set(item, 'iconUrl', obj.path);
              }
            });
            //渲染应用产品后，渲染图表
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    msgMore() {
      window.open(
        location.origin +
          '/messageManagementList?staffGuid=' +
          this.currentStaffGuid +
          '&tenantGuid=' +
          this.currentTenantGuid,
      );
    },
    //切换图标tab
    changeTab(e) {},
  },
};
</script>

<style scoped>
.topApp p,
.noticeList p {
  padding: 0;
  margin: 0;
}
.homePage {
  background: #f6f7fa;
  min-height: 100vh;
  width: 100%;
  padding: 0.8rem 1rem 1rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.logoArea {
  display: flex;
  align-items: flex-end;
  padding-bottom: 1.5rem;
}

.logoArea img {
  height: 2.125rem;
  margin-right: 1.875rem;
}

.logoArea span {
  font-size: 1.25rem;
  color: #323233;
}

.contentArea {
  display: flex;
  /* flex: 1; */
  height: 100%;
  width: 100%;
  /* background: #fff; */
}

/* 左侧 */
.leftLayout {
  width: 70%;
  flex-shrink: 0;
  /* padding-left: 1rem; */
  margin-right: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.topApp {
  height: 100%;
  background: #fff;
  padding: 1.25rem 0 0.25rem 1.5rem;
  box-sizing: border-box;
}
.topApp .title {
  font-size: 1.1rem;
  color: #323233;
  /* border-bottom: 1px solid #DFE1E6; */
  /* margin-bottom: 1rem; */
  padding-bottom: 1rem;
  text-align: left;
  font-weight: 500;
}

.topApp .appBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.topApp .oneApp {
  width: 31%;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: left;
  /* background: #F6F7FA; */
  border: 1px solid #d9d9d9;
  margin-right: 2%;
  margin-bottom: 1rem;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
}

.topApp .oneApp:hover {
  background: #fafafa;
  transition: all 0.5s;
  box-shadow: 0px 0 0.625rem 3px rgba(0, 0, 0, 0.1);
}

.topApp .oneApp img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.topApp .oneApp .p1 {
  font-size: 1.1rem;
  margin-bottom: 4px;
  font-weight: 400;
}

.topApp .oneApp .p2 {
  font-size: 0.8rem;
  color: #6e6e6e;
}
/* 右侧 */
.rightLayout {
  width: calc(30% - 4px);
  /* height: 100%; */
  flex: 1;
  background: #fff;
}
.rightLayout .notice-list {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  line-height: 46px;
  box-sizing: border-box;
}
.rightLayout .notice-list:hover {
  background: #fafafa;
  /* cursor: pointer; */
}
.rightLayout .notice-list-left {
  flex: 1;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.rightLayout .notice-list-left span {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #fb2323;
  border-radius: 50%;
  margin-right: 8px;
}
.notice-list-right {
  font-size: 12px;
  flex-shrink: 0;
  padding-left: 10px;
}
/* 页面tab */
.common-tab-box {
  display: flex;
  justify-content: space-between;
  height: 32px;
  line-height: 32px;
  color: #666;
  margin: 16px 16px 4px 16px;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
  background: #fff;
}
.common-tab-left {
  display: flex;
}
.common-tab-list {
  padding: 0 20px;
  /* margin: 0 5px; */
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
}
.common-tab-active {
  color: #4fa1a4;
  border-bottom: 2px solid #4fa1a4;
}
.common-tab-right {
  font-size: 12px;
  color: #666666;
  cursor: pointer;
  margin-right: 5px;
}
.no-data {
  color: #666;
  font-size: 12px;
  margin: 20px;
}
.iframe-sty {
  width: 100%;
  height: 1600px;
  border: none;
  /* background:grey; */
}
.report-content{
  margin-top: 4px;
}
</style>
<style>
  ::-webkit-scrollbar {
    width: 10px!important;
  }
</style>

